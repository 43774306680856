
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { PromiseHolder } from '@common/utils/PromiseHolder';
import { getNextWebServerSidePropsWrapper, GetProps, GetPropsReturn, NextWebServerSideProps, Route, } from '@web/routing';
import { fetchCategories, fetchCategoryVideos, fetchVideos } from '@web/templates/VideoCategoryOverview';
import { Props, State, VideoOverview } from '@web/templates/VideoOverview';
const prepareProps: GetProps<Props> = async (contextData): GetPropsReturn<Props> => {
    const state: PromiseHolder<State> = {
        currentPage: 1,
        generalVideos: [],
        categoryVideos: []
    };
    const props: PromiseHolder<Props> = {
        contextData,
        state: state
    };
    const generalVideosPromise = fetchVideos(contextData, 1, false);
    const categories = await fetchCategories(contextData, false);
    state.categoryVideos = fetchCategoryVideos(contextData, categories);
    const generalVideos = await generalVideosPromise;
    state.generalVideos = generalVideos?.data || [];
    state.pagination = generalVideos?.pagination;
    return props;
};
const getServerSideProps: NextWebServerSideProps<Props> = getNextWebServerSidePropsWrapper(prepareProps, Route.VideoOverview);
export const Home = VideoOverview;
export default Home;

    async function __Next_Translate__getServerSideProps__1930710f8c4__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/videos/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__1930710f8c4__ as getServerSideProps }
  